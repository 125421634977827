if (!window.requestIdleCallback) {
    window.requestIdleCallback = function (callback, options = {}) {
        var relaxation = 1;
        var timeout = options.timeout || relaxation;
        var start = performance.now();
        return setTimeout(function () {
            callback({
                get didTimeout() {
                    return options.timeout ? false : performance.now() - start - relaxation > timeout;
                },
                timeRemaining: function () {
                    return Math.max(0, relaxation + (performance.now() - start));
                },
            });
        }, relaxation);
    };
}

if (!window.cancelIdleCallback) {
    window.cancelIdleCallback = function (id) {
        clearTimeout(id);
    };
}

if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback) {
        return window.setTimeout(function () {
            callback(Date.now());
        }, 1000 / 60);
    };
}

if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
        clearTimeout(id);
    };
}
